/**
 * soubor se seznamem veškerých graphql dotazů pro změnu hesla
 */
 import gql from 'graphql-tag';

 export const REGISTER_NEW_CUSTOMER = gql`
     mutation register($customerData:CustomerData,$domain:String) {
        register(customerData:$customerData,domain:$domain){
            customerID            
            error
        }
     }
 `;

 export const CONFIRM_REGISTER = gql`
 mutation confirmAccount($regCode:String) {
    confirmAccount(regCode:$regCode){          
        error
    }
 }
`;

export const GET_REGISTRATION_DATA = gql`
    query GetRegistrationData {
        allCountries{
            countryID
            name
        }
        
    }
`;


 
 