/**
 *  Pomocná třída s obecnými funkcemi
 */

class Helper{

    getApolloErrorText(error){

        error = JSON.stringify(error);
        error = JSON.parse(error);

        if(error.graphQLErrors[0])return error.graphQLErrors[0].message;
        if(error.networkError && error.networkError.result.errors){
            return error.networkError.result.errors[0].message;
        }

    }

    IsIOS = () => (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

    DetectMobAndtablet() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
        
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
        });
    }

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    FormatTime(seconds)
    {
        const modulo = seconds % 3600;

        var hours = Math.floor(seconds / 3600);
        var minutes = Math.floor(modulo / 60);
        var secs = Math.floor(modulo % 60);

        const hoursFormat = (hours < 10 ? "0" + hours : hours); 
        const minutesFormat = (minutes < 10 ? "0" + minutes : minutes);
        const secsFormat = (secs < 10 ? "0" + secs : secs);

        return (hours != 0 ?
            hoursFormat + ":" + minutesFormat + ":" + secsFormat
            :
            minutesFormat + ":" + secsFormat
        )

    }

    GetPriceWithCurrency(price,currency)
    {
        switch(currency)
        {
            case "$" : return "$" + price;
            case "£" : return "£" + price;
            case "Kč" : return price + "Kč";
        }
    }

    GetPriceString(price,lang,currencyCode,fraction = 0)
    {
        var l = lang;
        if(lang == "us")
            l = "en-us";
        
        return new Intl.NumberFormat(l, { 
            style: 'currency', 
            currency: currencyCode, 
            maximumFractionDigits : fraction
        }).format(price);
    }
}

export default Helper;