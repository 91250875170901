import React,{ useState } from 'react';
import {useMutation} from '@apollo/client';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Success from '../../../GlobalComponents/Success';
import {SAVE_NEW_PASSWORD} from '../Queries/changePassword';
import logo from '../../../Public/Images/logo.svg';
import keyIcon from '../../../Public/Images/Icons/key.png';
import { useTranslation } from 'react-i18next';
import Button from '../../../GlobalComponents/Button';
import { useNavigate,useParams } from "react-router-dom";
import Helper from '../../../Library/helper';

const NewPassword = () => {

    const {t,i18n} = useTranslation();
    const navigate = useNavigate();
    const { code } = useParams();

    const [disabled,SetDisabled] = useState(false);
	const [error,SetError] = useState("");
    const [message,SetMessage] = useState("");
	const [password,SetPassword] = useState("");
    const [checkPassword,SetCheckPassword] = useState("");

    const [SaveNewPassword,{loading}] = useMutation(SAVE_NEW_PASSWORD,{

		update : async (cache, response) => {

            if(response.data.saveNewPassword != ""){
                SetError(response.data.saveNewPassword);
                setTimeout(() => {SetError(''); SetDisabled(false);},3000);
            }else{	
                SetMessage(t('SuccessfullyChanged'));
            }

        },
		onError:(err) => {

			const helper = new Helper();
			err = helper.getApolloErrorText(err);
			SetError(err);
			setTimeout(() => {SetError('');SetDisabled(false)},4000);
		}
	});

    const GoBack = () => { 
		navigate("/")
	}
    
    const SavePassword = () => {

        SetDisabled(true);

        if(code && code !== "")
        {
            if(password !== '' && checkPassword !== '')
            {
                if(password === checkPassword)
                { 
                    SaveNewPassword({
                        variables:{
                            passCode:code,
                            password:password,
                            lang: i18n.language
                        }
                    });

                }
                else
                {
                    SetError(t('NewPwdsNotSame'));
                    setTimeout(() => {SetError(''); SetDisabled(false);},3000);
                }
            }
            else
            {
                SetError(t('NotFilledPwd'));
                setTimeout(() => {SetError(''); SetDisabled(false);},3000);
            }
        }
        else
        {
            SetError(t('WrongUser'));
            setTimeout(() => {SetError(''); SetDisabled(false);},3000);
        }

    }
	  
    return (
    	<div id="auth-panel">

            <div className="logo">
                <img alt={t('AstralProjection')} src={logo} />
            </div>

        	<div className="card">
				
				<div className="card-header"><span>{t('AstralProjection')}</span></div>
				<div className="card-body">

                    {message == "" ?
                        <>
                            <p className="form-group no-top-margin">{t('ChooseYourPwd')}</p>
                            <div className="form-group">
                                
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <img alt="" src={keyIcon} />
                                </div>
                                <input
                                    onChange={e => SetPassword(e.target.value)}
                                    value={password}
                                    type="password" className="form-control" placeholder={t('NewPassword')} aria-label={t('NewPassword')} />
                                </div>

                            </div>
                            <div className="form-group">
                                                        
                                <div className="input-group">
                                <div className="input-group-prepend">
                                    <img alt="" src={keyIcon} />
                                </div>
                                <input
                                    onChange={e => SetCheckPassword(e.target.value)}
                                    value={checkPassword}
                                    type="password" placeholder={t('RepeatNewPwd')} className="form-control" aria-label={t('RepeatNewPwd')} />
                                </div>
                                
                            </div>
                        </>
                    :null}

                    <div>
                        
                        {message != "" ?
                            <Button onClick={() => GoBack()} className="btn btn-primary btn-block">{t('BackToLogin')}</Button> 
                        :
                            <Button disabled={disabled} loading={loading} onClick={() => SavePassword()} className="btn btn-primary btn-block">{t('Save')}</Button> 
                        }
                    </div>

                    {error && <Error text={error} />}
                    {message && <Success text={message} />}
                               
                                    
    			</div>
    		</div>    			
        </div>
    );
  
}

export default NewPassword;
