import gql from 'graphql-tag';

export const CREATE_PAYMENT = gql`
    mutation CreatePayment(
        $customerID:ID!,
        $token:String!,
        $membershipDuration:ID!,
        $name: String,
        $surname: String,
        $email: String,
        $city: String,
        $street: String,
        $zip: String
        $autoPayment:Boolean
        $domain:String
        $tel:String
        $serviceID: ID
        $customerServiceID:ID
        $url:String
        $additionalServicesID:[ID]
        $linkID:ID
        $hasStoredPaymentMethod:Boolean
    ){
        createPayment(
            customerID:$customerID,
            token:$token,
            membershipDuration:$membershipDuration,
            name:$name,
            surname:$surname,
            email:$email,
            city:$city,
            street:$street,
            zip:$zip,
            autoPayment:$autoPayment
            domain:$domain
            tel:$tel
            serviceID:$serviceID
            customerServiceID:$customerServiceID
            url:$url
            additionalServicesID:$additionalServicesID
            linkID:$linkID
            hasStoredPaymentMethod:$hasStoredPaymentMethod
        ){
            customerPaymentID
            status
            clientSecret
            thanksPage
        }   
    }
`;