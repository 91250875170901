import {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateDataWithExtendMembership } from './customer';
import { useApolloClient } from '@apollo/client';


export const UsePersonalData = () => {

    const {t}  = useTranslation();

    const [success, SetSucces]  = useState("");
    const [error, SetError]     = useState("");
    const [loading, SetLoading] = useState(false);

    const client    = useApolloClient();
    
    const [formData, SetFormData] = useState({
        customerID:0,
        name:"",
        email:"",
        surname:"",
        street:"",
        city:"",
        zip:"",
        state:"",
        countryID:60,
        tel:""
    });

    const InitFormData = (data) => {
        SetFormData({...formData,...data});
    }

    const FormHandle = (e) => {

        var t = e.target;
        var v = t.type === 'checkbox' ? t.checked : t.value;
        var n = t.name;

        SetFormData({...formData,[n]:v});
    }

    const ChangeData = async () => {

        var err = CheckPersonalData();
        if(err == "")
        {
            SetLoading(true);

            try{

                if(formData.countryID == 239)
                    formData.city = formData.city + ", " + formData.state;

                await UpdateDataWithExtendMembership({
                    customerID:             formData.customerID,
                    name:                   formData.name,
                    surname:                formData.surname,
                    email:                  formData.email,
                    city:                   formData.city,
                    street:                 formData.street,
                    zip:                    formData.zip,
                    tel:                    formData.tel,
                    countryID:              formData.countryID,
                    domain:                 "",
                    newsletter:             false,
                    social:                 ""
                },client);

                SetLoading(false);
                SetSucces(t("SuccessfullyChanged"));
                setTimeout(() =>SetSucces (""),4000);

            }
            catch(err)
            {
                SetLoading(false);
                SetError(err);
            }

        }
        else
        {
            SetError(err);
            setTimeout(() =>SetError (""),4000);
        }

    }

    const CheckPersonalData = () =>
    {
        if(formData.name != ""){

            if(formData.surname != ""){

                if(formData.street != ""){

                    if(formData.city != ""){

                        if(formData.zip != ""){

                            if(formData.countryID != ""){

                                if(formData.tel != ""){

                                    return("");
                        
                                }else
                                    return t("InvalidTel");
                            }else
                                return t("InvalidCountry");
                        }else
                            return t("InvalidZip");
                    }else
                        return t("InvalidCity");
                }else
                    return t("InvalidStreet");
            }else
                return t("InvalidSurname");
        }else
            return t("InvalidName");  
    }

    return{
        error,
        success,
        formData,
        loading,
        FormHandle,
        ChangeData,
        InitFormData
    }
}