/**
 *  Třída, která vkládá notifikace
 */

import {GET_NOTIFICATIONS} from '../GlobalQueries/globalQueries';

class Notification{

    setNotification(cache,text,success,error,client){

        cache = cache || client;
        
        const { notifications } = cache.readQuery({ query: GET_NOTIFICATIONS });
        cache.writeQuery({ 
            query:GET_NOTIFICATIONS,
            data:{
                notifications: [{text,success,error,__typename:"Notification"},...notifications]
            } 
        });

    }

}

export default Notification;