import {useEffect,useRef,useState} from 'react';
import previous from '../../../Public/Images/Icons/previous.svg';
import next from '../../../Public/Images/Icons/next.svg';
import play from '../../../Public/Images/Icons/play_with_circle.svg';
import pause from '../../../Public/Images/Icons/pause.svg';
import ProgressBar from './ProgressBar';
import Volume from './Volume';
import { usePlayer } from '../Library/usePlayer';
import Helper from '../../../Library/helper';
import { SERVER_URL,AUDIO_URL } from '../../../Config';
import {Buffer} from 'buffer';
import Player from '@vimeo/player';
import close from '../../../Public/Images/Icons/close.png'
import Payment from '../../Payment/Components/Payment';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

const MediaPlayer = () => {

    const {
        selectedAudio,
        isPlaying,
        SetIsPlaying,
        SaveViewedDate,
        SetSelectedAudio,
        AudioPlayHack,
        SetMembershipDurationID,
        SetFirstInit,
        descRef,
        descModalRef
    } = usePlayer(); 

    const audioRef  = useRef();
    const helper    = new Helper();
    var videoRef    = useRef();
    const {t,i18n}  = useTranslation();

    const [showVideo, SetShowVideo] = useState(false);
    const [isPortrait, SetIsPortrait] = useState(false);
    const [membershipToBuy, SetMembershipToBuy] = useState(false);

    useEffect(() => {

        Resize();
        
        window.addEventListener("resize",Resize);
        return () => {
            SetSelectedAudio(null);
            SetFirstInit(false);
            window.removeEventListener("resize",Resize);
        }
    },[])

    useEffect(() => {

        if(selectedAudio && selectedAudio.audioTypeID == 2)
        {
            videoRef.current = new Player('video-player', {
                id: selectedAudio.videoUrl,
                height: 800,
                controls:false
            });

            var NestPlay = function(data) {
                AudioPlayHack(selectedAudio.nextAudioIndex,true);
            };
            videoRef.current.on('ended', NestPlay);
        }
        else{
            if(videoRef.current)
                RemoveVideo();
        }

        if(selectedAudio && selectedAudio.autoPlay)
            PlaySound();
        else if(selectedAudio && !selectedAudio.autoPlay)
        {
            PauseSound();
        }
        else if(!selectedAudio)
        {
            const au = localStorage.getItem("au-ap-s");
            if(au)
            {
                const parsedData = JSON.parse(Buffer.from(au,"base64").toString());
                SetMembershipDurationID(parsedData.membershipDurationID); 
                SetSelectedAudio(parsedData);
            }
        }

        return () => {

            if(selectedAudio && selectedAudio.audioTypeID == 2)
                videoRef.current.destroy();
        }

    },[selectedAudio])

    

    useEffect(()=>{

        if(showVideo && videoRef.current)
        {
            videoRef.current.play().catch((err) => {});
        }

    },[showVideo, videoRef.current])

    const PlaySound = () => {

        if(selectedAudio.customerAudioID || selectedAudio.activeForUser)
        {
            SetIsPlaying(true);
            if(selectedAudio.audioTypeID == 1)
                audioRef.current.play();
            else if(selectedAudio.audioTypeID == 2)
            {
                if(!showVideo) SetShowVideo(true);
                else 
                    videoRef.current.play().catch((err) => {});
            }
            else if(selectedAudio.audioTypeID == 3)
            {
                descRef.current.scrollTo(0,0);
                if(descModalRef.current)
                    descModalRef.current.scrollTo(0,0);
            }
            SaveViewedDate(selectedAudio.audioID);
        }
        else
        {
            SetMembershipToBuy(selectedAudio.membership)
        }
    }
    const PauseSound = () => {
        if(selectedAudio.audioTypeID == 1)
            audioRef.current.pause();
        else if(selectedAudio.audioTypeID == 2)
        {
            if(videoRef.current){
                videoRef.current.pause().catch((err) => {});
            }
        }
        SetIsPlaying(false);
    }
    const AudioEnded = () => {
        AudioPlayHack(selectedAudio.nextAudioIndex,true);
    }

    const CloseVideo = () => {

        PauseSound();
        SetShowVideo(false)
    }

    const RemoveVideo = () => {
        SetShowVideo(false);
        PauseSound();
        videoRef.current.destroy();
        videoRef.current = null;
    }

    const Resize = () => {

        var sideBarWidth = 230;
        if(window.innerWidth <= 992)
            sideBarWidth = 180;
        if(window.innerWidth <= 768)
            sideBarWidth = 0;

       const w = window.innerWidth - sideBarWidth;
       const h = window.innerHeight - 120;

       if(w/h < 16/9)
         SetIsPortrait(true);
       else
        SetIsPortrait(false);

    }

    var audioUrl = "";
    if(selectedAudio && selectedAudio.audioTypeID == 1 )
    {
        var l = i18n.language;
        if(i18n.language == "us")
            l = "en";

        if(selectedAudio.file.indexOf("_" + l) != -1)
            audioUrl = SERVER_URL + "/" + AUDIO_URL + "/" + selectedAudio.hash + "/" + selectedAudio.file;
        else
            audioUrl = SERVER_URL + "/" + AUDIO_URL + "/" + selectedAudio.file;
    }
        
    
    return(
        <div id="player">

            <div className="dot tl"></div>
			<div className="dot tr"></div>

            {selectedAudio ?
                <div className={"controls-content" + (selectedAudio.audioTypeID == 3 ? " text-controls" : "")}>

                    {selectedAudio.audioTypeID == 1 ?
                        <audio 
                            onEnded={() => AudioEnded()}
                            src={audioUrl} 
                            ref={audioRef}
                        ></audio>
                    :
                        (selectedAudio.audioTypeID == 2 ?
                            <div id="video-player-overlay" className={(showVideo ? "" : "hidden")}>
                                <div id="video-player" className={isPortrait ? "portrait" : ""}></div>  
                                <img className="close" src={close} onClick={() => CloseVideo()} />
                            </div>
                        :null)
                    }
                    {selectedAudio.audioTypeID == 3 ?
                        <h3 className="text-center no-bottom-margin no-top-margin"><span>{selectedAudio.name}</span></h3>
                    :null}
                    <div className={"main-controls"}>
                        
                        <img onClick={() => AudioPlayHack(selectedAudio.prevAudioIndex,true)} src={previous} />
                        {selectedAudio.audioTypeID != 3 ?
                            (isPlaying ?
                                <img onClick={() => PauseSound()} src={pause} className="play" />
                            :
                                <img onClick={() => PlaySound()} src={play} className="play" />
                            )
                        :
                            <span className="prev-next-space"></span>
                        }
                        <img onClick={() => AudioPlayHack(selectedAudio.nextAudioIndex,true)} src={next} />

                        {selectedAudio.audioTypeID != 3 && !helper.DetectMobAndtablet() ?
                            <Volume audioRef={audioRef} videoRef={videoRef}  />
                        :null}
                        
                    </div>
                    {selectedAudio.audioTypeID != 3 ?
                        <ProgressBar videoRef = {videoRef} audioRef={audioRef} isPlaying={isPlaying} totalDuration = {selectedAudio.duration} totalSeconds = {selectedAudio.totalSeconds} />
                    :null}
                </div>
            :null}

            <Modal
                isOpen={!!membershipToBuy}
                onRequestClose={() => SetMembershipToBuy(false)}
            >
                <div className="modal-content">
                    <div className="modal-header padd">
                        <span>{t("Payment")}</span>
                        <img src={close} className="close" onClick={() => SetMembershipToBuy(false)} />
                    </div>
                    <div className="modal-body">
                        <Payment membership={membershipToBuy} />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default MediaPlayer;