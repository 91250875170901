import React,{ useEffect, useState } from 'react';
import logo from '../../../Public/Images/logo.svg';
import { useTranslation } from 'react-i18next';
import {useMutation,useQuery} from '@apollo/client';
import {CONFIRM_REGISTER} from '../Queries/registration';
import { GET_CUSTOMER_BY_REG_CODE } from '../../Customer/Queries/customer';
import Helper from '../../../Library/helper';
import Error from '../../../GlobalComponents/Error';
import Success from '../../../GlobalComponents/Success';
import Loading from '../../../GlobalComponents/Loading';
import { useNavigate,useParams } from "react-router-dom";


const ConfirmRegistration = (props) => {

    const {t,i18n} = useTranslation();
    const [error,SetError] = useState("");
    const [success,SetSuccess] = useState("");
    const {regCode} = useParams();

    const {data,loading:customerLoading} = useQuery(GET_CUSTOMER_BY_REG_CODE,{
		fetchPolicy: 'network-only',
        variables:{
            regCode:regCode
        }
	});

    const [confirmAccount,{loading}] = useMutation(CONFIRM_REGISTER,{

		onCompleted : async (data) => {

            if(data.confirmAccount.error != "")SetError(data.confirmAccount.error);
            else{  
                SetSuccess(t("SuccessConfirmationApp")); 
            }

        },
		onError:(err) => {

			const helper = new Helper();
			err = helper.getApolloErrorText(err);
			SetError(err);
			setTimeout(() => {SetError('')},4000);
		}
	});

    useEffect(() => {

        if(data)
        {
            i18n.changeLanguage(data.customerByRegCode.domain.lang);
            confirmAccount({
                variables:{
                    regCode
                }
            });
        }

    },[data])
    
		   		  
    return (
        <div id="auth-panel">
            {loading || customerLoading ?
				<Loading />
			:
                <>
                    <div className="logo">
						<img alt={t('AstralProjection')} src={logo} />
					</div>

                    <div className="card o-hidden">
                        
                        <div className="card-header"><span>{t('AstralProjection')}</span></div>
                        <div className="card-body">
                            
                            <div className="form-group">
                            
                                {error ?
                                    <Error text={error} />
                                :null}
                                {success ?
                                    <Success text={success} />
                                :null}

                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
  
}

export default ConfirmRegistration;
