import gql from 'graphql-tag';

export const GET_MEMBERSHIP_DATA = gql`
  query MembershipData($membershipDurationID: ID,$lang:String) {
    
    allCustomerMembershipAudios(membershipDurationID: $membershipDurationID,lang: $lang){
      audioID
      hash
      membershipDurationID
      customerAudioID
      audioTypeID
      videoUrl
      name
      file
      duration
      viewed
      text
      lastViewedAudio
      lastViewedAudioTime
      dateViewed
      lang
      activeForUser
      membership{
        membershipDurationID
        name
        photo
        description
        lang
        value
        additionalServices{
          additionalServiceID
          name
          price
          photo
        }
      }
  }
  }
`;

export const UPDATE_VIEWED_AUDIO = gql`
    mutation UpdateViewedAudio($audioID: ID!){
        updateViewedAudio(audioID:$audioID){
          audioID
          dateViewed
          lastViewedAudio
        } 
    }
`;