import {useState} from 'react';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import { useMutation, useApolloClient } from '@apollo/client';
import Helper from '../../../Library/helper';
import { useTranslation } from 'react-i18next';
import { UpdateDataWithExtendMembership } from '../../Customer/Library/customer';
import {CREATE_PAYMENT} from '../Queries/payment';
import { GET_MEMBERSHIP_DATA } from '../../Player/Queries/audio';
import { GET_HEADER_DATA } from '../../../GlobalQueries/globalQueries';

export const UsePayment = () => {

    const stripe    = useStripe();
    const elements  = useElements();
    const client    = useApolloClient();
    const {t,i18n}  = useTranslation();

    const [paymentError, SetPaymentError] = useState(null);
    const [succesPayment, SetSuccesPayment] = useState(false);
    const [error, SetError] = useState("");
    const [loading, SetLoading] = useState(false);

    const [additionalServices, SetAdditionalServices] = useState([]);
    
    const [formData, SetFormData] = useState({
        customerID:0,
        domainID:0,
        email:"",
        name:"",
        surname:"",
        street:"",
        city:"",
        zip:"",
        state:"",
        countryID:60,
        tel:"",
        creditCard:"",
        payuCond:false,
        cvv:"",
        expMonth:"",
        expYear:"",
        domain:"",
        membershipDurationID:0,
    });

    const FormHandle = (e) => {

        var t = e.target;
        var v = t.type === 'checkbox' ? t.checked : t.value;
        var n = t.name;

        if(n == "cvv" && v.length > 3)v = formData.cvv;
        if(n == "expMonth" && v.length > 2)v = formData.expMonth;
        if(n == "expYear" && v.length > 2)v = formData.expYear;
        if(n == "creditCard" && v.length % 5 == 4 && v.length < 19 && v.length > formData.creditCard.length)v = v + " ";
        if(n == "creditCard" && v.length > 19)v = formData.creditCard;

        SetFormData({...formData,[n]:v});
    }

    const InitFormData = (data,membership) => {

        SetFormData({...formData,...data});
        
        if(membership.additionalServices)
        {
            var additionalServiceIDs = [];
            for(let val of membership.additionalServices)
            {
                additionalServiceIDs.push(val.additionalServiceID);
            }

            SetAdditionalServices(additionalServiceIDs);
        }
    }

    const DoPayment = async (CardElement) => {

        var error = CheckPersonalData();
        if(error == "")
        {
            var result;

            if(CardElement)
                result = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement)
                });

            if(result && result.error){

                SetLoading(false);
                SetError(result.error.message);
                setTimeout(() => SetError (""),4000);

            }else{

                SetLoading(true);

                if(formData.countryID == 239)
                    formData.city = formData.city + ", " + formData.state;

                try
                {
                    await UpdateDataWithExtendMembership({
                        customerID:             formData.customerID,
                        name:                   formData.name,
                        surname:                formData.surname,
                        email:                  formData.email,
                        city:                   formData.city,
                        street:                 formData.street,
                        zip:                    formData.zip,
                        tel:                    formData.tel,
                        countryID:              formData.countryID,
                        domain:                 formData.domain,
                        newsletter:             false,
                        social:                 ""
                    },client);

                    var cpd = await CreatePayment(result ? result.paymentMethod.id : "", result ? false : true);                                 
                    if(cpd.data.createPayment.status == "active" || cpd.data.createPayment.status == "succeeded" || cpd.data.createPayment.status == "trialing"){
                                                
                        //úspěšně zapolaceno
                        SetLoading(false);
                        SetSuccesPayment(true);
                        OnSuccessfulPayment(formData.membershipDurationID,i18n,client);

                    }else{

                        stripe.confirmCardPayment(cpd.data.createPayment.clientSecret, {
                            payment_method: result ? result.paymentMethod.id : cpd.data.createPayment.paymentMethodID
                        })
                        .then(async (result) => {

                            if(result.error){

                                SetLoading(false);
                                SetPaymentError("Autorizace vaši platby neproběhla v pořádku. Platbu je třeba provést znovu.");

                            }else{

                                //úspěšně zapolaceno
                                SetLoading(false);
                                SetSuccesPayment(true);
                                OnSuccessfulPayment(formData.membershipDurationID,i18n,client);

                            }
                        });
                    }

                }catch(err){
                    SetLoading(false);
                    SetPaymentError(err);
                } 
            }

        }
        else
        {
            SetError(error);
            setTimeout(() =>SetError (""),4000);
        }

    }

    const CreatePayment = (token,hasStoredPaymentMethod) => {

        return new Promise(async (resolve,rejected) => {
            
            try{

                var data = await client.mutate({
                    mutation:CREATE_PAYMENT,
                    variables:{
                        token,
                        hasStoredPaymentMethod,
                        customerID:             formData.customerID,
                        membershipDuration:     formData.membershipDurationID,
                        name:                   formData.name,
                        surname:                formData.surname,
                        email:                  formData.email,
                        city:                   formData.city,
                        street:                 formData.street,
                        zip:                    formData.zip,
                        autoPayment:            false,
                        domain:                 formData.domain,
                        tel:                    formData.tel,
                        serviceID:              0,
                        customerServiceID:      0,
                        url:                    "",
                        additionalServicesID:   additionalServices,  
                        linkID:                 0
                    }
                });

                resolve(data);

            }catch(err){

                var helper = new Helper();
                err = helper.getApolloErrorText(err);

                rejected(err);
            }
        });
    }

    const CheckPersonalData = () =>
    {
        if(formData.name != ""){

            if(formData.surname != ""){

                if(formData.street != ""){

                    if(formData.city != ""){

                        if(formData.zip != ""){

                            if(formData.countryID != ""){

                                if(formData.tel != ""){

                                    return("");
                        
                                }else
                                    return t("InvalidTel");
                            }else
                                return t("InvalidCountry");
                        }else
                            return t("InvalidZip");
                    }else
                        return t("InvalidCity");
                }else
                    return t("InvalidStreet");
            }else
                return t("InvalidSurname");
        }else
            return t("InvalidName");  
    }

    return[
        error,
        paymentError,
        succesPayment,
        formData,
        loading,
        FormHandle,
        InitFormData,
        SetPaymentError,
        DoPayment
    ]
}

export const OnSuccessfulPayment = (membershipDurationID,i18n,client) => {

    const variables = {
        membershipDurationID:membershipDurationID,
        lang:i18n.language
    }
    const hVariables = {
        lang:i18n.language
    }
    
    const data = client.readQuery({ query: GET_MEMBERSHIP_DATA,variables:variables});
    const headerData = client.readQuery({ query: GET_HEADER_DATA,variables:hVariables});
    
    if(data)
    {
        const { allCustomerMembershipAudios } = data;
        var arr = [...allCustomerMembershipAudios];
        
        arr.forEach((item,index) => {

            //dávám zde jedničku a ne konkrétní ID, prorože se tato hodnoto používá jen pro to, aby se ukázala platba.
            //když bude jednička, tak už nebude platba vyskakovat.
            arr[index] = {...arr[index],
                customerAudioID:1
            };

        });
        
        client.writeQuery({ 
            query:GET_MEMBERSHIP_DATA,
            variables:variables,
            data:{
                allCustomerMembershipAudios: arr
            } 
        });
    }

    if(headerData)
    {
        const { allMemberships,customerContext,allCategories } = headerData;
        
        var cc = {...customerContext};
        var ccM = [...customerContext.memberships];

        ccM.push({
            membershipDurationID: membershipDurationID
        })
        cc["memberships"] = ccM;
         
        client.writeQuery({ 
            query:GET_HEADER_DATA,
            variables:hVariables,
            data:{
                customerContext: cc,
                allMemberships,
                allCategories
            } 
        });
    }
    

}