import React,{ Component } from 'react';
import { useTranslation } from 'react-i18next';

const Loading = ({color,useText}) => {

    const {t} = useTranslation();
	
    return (
    	<div className="whole-loading">	
            <div className="loading"><div className="spinner"><div className="rect1"></div><div className="rect2"></div><div className="rect3"></div><div className="rect4"></div><div className="rect5"></div></div><div className={"text" + (color ? " " + color : "")}>{(useText ? t("Loading") + "..." : "")}</div></div>
    	</div>
    );
  
}


export default Loading;
