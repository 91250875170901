/*
    Formulář pro přihlášení se do adminu
*/
import React,{ useEffect, useState } from 'react';
import { Link,useNavigate } from "react-router-dom";
import {useMutation,useQuery } from '@apollo/client';
import logo from '../../../Public/Images/logo.svg';
import emailIcon from '../../../Public/Images/Icons/email_24.png';
import keyIcon from '../../../Public/Images/Icons/key.png';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../Library/useAuth';
import { LOGIN_USER } from '../Queries/login';
import { GET_CUSTOMER } from '../../Customer/Queries/customer';
import Error from '../../../GlobalComponents/Error';
import Button from '../../../GlobalComponents/Button';
import Helper from '../../../Library/helper';
import Cookies from 'universal-cookie';
import Loading from '../../../GlobalComponents/Loading';
import ChooseLanguage from './ChooseLanguage';

const Login = () => {

	const cookies = new Cookies();

	const { SaveToken,showAuth,SetShowAuth,token } = useAuth();

	const {t,i18n} = useTranslation();
	const navigate = useNavigate();

	const [email,SetEmail] = useState("");
	const [disabled,SetDisabled] = useState(false);
    const [password,SetPassword] = useState("");
    const [error,SetError] = useState("");

	const {data,refetch} = useQuery(GET_CUSTOMER,{
		fetchPolicy: 'network-only',
		onCompleted:(d) => {
			
			if(d.customerContext.customerID && token)
			{
				navigate("/signpost");
			}
			else
				SetShowAuth(true);	
		}
	});

	const [login,{loading}] = useMutation(LOGIN_USER,{

		onCompleted: async (data) => {

			if(!data.login)
			{
				SetError(t('WronCredentialInserted'));
				setTimeout(() => {SetError(''); SetDisabled(false)},3000);
			}
			else
			{
				if(data.login.customer.confirm == 1)
				{
					SaveToken(data.login.loginToken);

					var url = localStorage.getItem("urlToShow");
					localStorage.setItem("urlToShow", "");

					if(url && url != "")
						navigate(url);
					else{
						navigate("/signpost");
					}
				}
				else
				{
					SetError(t('NotConfirmedAccount'));
					setTimeout(() => {SetError(''); SetDisabled(false)},3000);
				}
					

			}
		},
		onError:(err) => {

			const helper = new Helper();
			err = helper.getApolloErrorText(err);
			SetError(err);
			setTimeout(() => {SetError(''); SetDisabled(false)},3000);
		}
	});
		
	const CheckLogin = () => {

		SetDisabled(true);
						
		if(password !== '' && email !== '')
		{
			login({
				variables:{
					email,
					password,
					lang:i18n.language
				}
			});  
			
		}else{
			SetDisabled(false);
			SetError(t('NotFilledEmailOrPwd'));
			setTimeout(() => {SetError(''); SetDisabled(false)},3000);
		}
	}

	const PressKey = (e) => {
		if(e.key == "Enter")
			CheckLogin();
	}

	useEffect(() => {

		var l = cookies.get("aup_lang");
        if(l)
			i18n.changeLanguage(l);

	},[])

	useEffect(() => {

		document.addEventListener("keyup",PressKey);
		return () => {
			document.removeEventListener("keyup",PressKey);
		}

	},[email,password])
	  
    return (
        <div id="auth-panel">
			{!showAuth ?
				<Loading />
			:
				<>
					<div className="logo">
						<img alt={t('AstralProjection')} src={logo} />
					</div>
					<div className="card">

						<div className="card-header"><span>{t('AstralProjection')}</span></div>
						<div className="card-body">

							<div className="form-group">
								
								<div className="input-group">
									<div className="input-group-prepend">
										<img alt="" src={emailIcon} />
									</div>
									<input
										onChange={e => SetEmail(e.target.value)}
										value={email}
										type="text" className="form-control" placeholder={t('Email')} aria-label="Email" 
									/>
								</div>

							</div>
							<div className="form-group">
														
								<div className="input-group">
									<div className="input-group-prepend">
										<img alt="" src={keyIcon} />
									</div>
									<input
										onChange={e => SetPassword(e.target.value)}
										value={password}
										type="password" placeholder={t('Password')} className="form-control" aria-label="Password" 
									/>
								</div>
								
							</div>
							
							<div className="form-group d-flex">
								<Button 
									disabled={disabled} 
									loading={loading} 
									onClick={() => CheckLogin()}
									className="btn btn-primary flex-1"
								>{t('LogIn')}</Button>
								
								<ChooseLanguage 
									allWorldRegionsWithLangs = {data && data.allWorldRegionsWithLangs}
								/>
								
							</div>
							
							{error ? 
								<Error text = {error} />
							:null}
							
							<div className="text-center">
								<Link to='/reset-password'>{t('ForgottenPwdQuestion')}</Link>
							</div>
								
						</div>
					</div>
				</>
			}
        </div>
    );
  
}

export default Login;
