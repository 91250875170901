import React, { useState, useContext, createContext, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_VIEWED_AUDIO, GET_MEMBERSHIP_DATA } from '../Queries/audio';
import { useTranslation } from 'react-i18next';
import {Buffer} from 'buffer';
import Helper from '../../../Library/helper';

const playerContext = createContext();

export const PlayerProvider = ({children}) => {

    const player = useProviderPlayer();

    return(
        <playerContext.Provider value={player}>
            {children}
        </playerContext.Provider>
    )
}

export const usePlayer = () => {
    return useContext(playerContext);
}

const useProviderPlayer = () => {

    const {i18n,t} = useTranslation();

    var descRef = useRef();
    var descModalRef = useRef();

    const [firstInit, SetFirstInit]                         = useState(false);
    const [selectedAudio, SetSelectedAudio]                 = useState(null);
    const [membershipDurationID, SetMembershipDurationID]   = useState(0);
    const [audioList, SetAudioList]                         = useState([]);
    const [isPlaying, SetIsPlaying]                         = useState(false);
    const [error, SetError]                                 = useState("");

    const {loading} = useQuery(GET_MEMBERSHIP_DATA,{
        variables:{
            membershipDurationID:membershipDurationID,
            lang:i18n.language
        },
        skip: membershipDurationID == 0,
        onCompleted:(data) => {

            if(data && data.allCustomerMembershipAudios && data.allCustomerMembershipAudios.length > 0)
            {
                SetError("");
                SetAudioList(data.allCustomerMembershipAudios);
            }
            else if(data && data.allCustomerMembershipAudios && data.allCustomerMembershipAudios.length == 0)
            {
                SetError(t("NoAudio"));
            }
        },
        onError:(error) => {

            var helper = new Helper();
            error = helper.getApolloErrorText(error);
            SetError(error);
        },
        fetchPolicy: 'network-only'
    });


    const [updateViewedAudio,{data}] = useMutation(UPDATE_VIEWED_AUDIO,{
        update:(cache, response) => {

            const variables = {
                membershipDurationID:membershipDurationID,
                lang:i18n.language
            }

            const data = cache.readQuery({ query: GET_MEMBERSHIP_DATA,variables:variables});
            
            if(data)
            {
                const { allCustomerMembershipAudios } = data;
                var arr = [...allCustomerMembershipAudios];
                
                arr.forEach((item,index) => {

                    arr[index] = {...arr[index],
                        lastViewedAudio:0,
                    };

                    if(item.audioID == response.data.updateViewedAudio.audioID)
                    {
                        arr[index] = {...arr[index],
                            dateViewed:response.data.updateViewedAudio.dateViewed,
                            lastViewedAudio:response.data.updateViewedAudio.lastViewedAudio,
                            viewed:true
                        };

                    }
                });
                
                cache.writeQuery({ 
                    query:GET_MEMBERSHIP_DATA,
                    variables:variables,
                    data:{
                        allCustomerMembershipAudios: arr
                    } 
                });
            }
        },
        onError:(err) => {
            
        }
    });

    const SelectAudio = (audioIndex,autoPlay) => {

        if(audioIndex || audioIndex == 0)
        {
            audioIndex = parseInt(audioIndex);

            const audio = audioList[audioIndex];

            var minutes = audio.duration.split(":");
            if(minutes[0] != "00")
                minutes = minutes[0] + " " + t("h.") + " " + minutes[1] + " " + t("min.");
            else if(minutes[1] != "00")
                minutes = minutes[1] + " " + t("min.");
            else
                minutes = minutes[2] + " " + t("sec.");  
    
            var totalSeconds = 0;
            var audioDuration = audio.duration.split(":");
            
            if(audioDuration[0] != "00")
            {
                totalSeconds = parseInt(audioDuration[0]) * 3600 + parseInt(audioDuration[1]) * 60 + parseInt(audioDuration[2]);
                audioDuration = audio.duration;
            }
            else
            {  
                totalSeconds = parseInt(audioDuration[1]) * 60 + parseInt(audioDuration[2]);
                audioDuration = audioDuration[1] + ":" + audioDuration[2];
            }   
    
            const sAudio = {
                audioID:                audio.audioID,
                hash:                   audio.hash,
                audioTypeID:            audio.audioTypeID,
                customerAudioID:        audio.customerAudioID,
                membershipDurationID:   audio.membershipDurationID,
                membership:             audio.membership,
                name:                   audio.name,
                text:                   audio.text,
                videoUrl:               audio.videoUrl,
                activeForUser:          audio.activeForUser,
                minutes:                minutes,
                file:                   audio.file,
                duration:               audioDuration,
                totalSeconds:           totalSeconds,
                autoPlay:               autoPlay,
                prevAudioIndex:         (audioIndex - 1 == -1 ? audioList.length - 1  : audioIndex - 1),
                nextAudioIndex:         (audioIndex + 1 == audioList.length ? 0 : audioIndex + 1)
            }

            var newAudio = {...sAudio};
            newAudio.autoPlay = false;

            localStorage.setItem("au-ap-s",Buffer.from(JSON.stringify(newAudio)).toString("base64"));
                
            SetSelectedAudio(sAudio);
        }else{
            SetSelectedAudio(null);
        }

    }

    const SaveViewedDate = (audioID) => {

        SetFirstInit(true);

        updateViewedAudio({
            variables:{
                audioID: audioID
            }
        })
    }

    const AudioPlayHack = (audioIndex,autoPlay) => {

        SelectAudio(audioIndex,autoPlay);
    }

    return {
        SelectAudio,
        SetIsPlaying,
        SaveViewedDate,
        SetSelectedAudio,
        SetMembershipDurationID,
        SetFirstInit,
        SetError,
        AudioPlayHack,
        selectedAudio,
        audioList,
        isPlaying,
        membershipDurationID,
        firstInit,
        error,
        loading,
        descRef,
        descModalRef
    }

}