/*
    Úvodní layout administrace
*/
import React,{ useEffect } from 'react';
//import {GET_CUSTOMER_DETAILS} from '../Modules/Customer/Queries/customer';
import Notification from '../GlobalComponents/Notification';

import Header from './Header';
import Player from '../Modules/Player/Components/Player';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../Modules/Auth/Library/useAuth';
import { useNavigate, Outlet } from "react-router-dom";
import { GET_CUSTOMER } from '../Modules/Auth/Queries/signpost';
import {useQuery } from '@apollo/client';
import Cookies from 'universal-cookie';
import { PlayerProvider } from '../Modules/Player/Library/usePlayer';

const Layout = (props) => {

	const {IsSignedIn,loading} = useAuth();
	const navigate = useNavigate();
	const {t,i18n} = useTranslation();

	const {data,loading:dataLoading,error} = useQuery(GET_CUSTOMER,{
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
        if(data && data.customerContext)
		{
			const cookies = new Cookies();
			const cookiesLang = cookies.get("aup_lang");

            if(!cookiesLang || data.customerContext.domain.lang != cookiesLang)
            {
        		i18n.changeLanguage(data.customerContext.domain.lang);
				cookies.set("aup_lang",data.customerContext.domain.lang,{path :"/",sameSite:"none",secure:true});
			}
			else
				i18n.changeLanguage(cookiesLang);
		}
    },[data])
	
	useEffect(() =>{
        if(!IsSignedIn() && !loading)navigate("/");
    },[IsSignedIn,loading])
      	
    return (
		<>
			{IsSignedIn() && !loading ?	   
				<PlayerProvider>
					<div className="whole-content">			       
					
						<Header />  
						<div className="l-content">
							
							<Outlet />	
							
						</div>
						<Player />
						<Notification />
								
					</div>
				</PlayerProvider>
			:null}
   		</>
    );
  
}

export default Layout;
