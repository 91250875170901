import { useEffect,useRef,useState } from "react";
import Helper from "../../../Library/helper";

const ProgressBar = ({totalDuration,totalSeconds,audioRef,videoRef}) => {

    const [time, SetTime]  = useState("00:00");
    const [progressPos, SetProgressPos]  = useState(100);

    const playAnimationRef = useRef();
    const helper = new Helper();

    useEffect(() => {

        return () => {
            if(audioRef.current)
                cancelAnimationFrame(playAnimationRef.current);
        }
    },[])

    useEffect(() => {

        if(videoRef.current)
        {
            SetProgressPos(100);
            SetTime("00:00");
            videoRef.current.on("timeupdate",VideoTimeUpdate);
        }

        return () => {
            if(videoRef.current)
                videoRef.current.off("timeupdate",VideoTimeUpdate);
        }
    },[videoRef.current])

    useEffect(() => {
 
        cancelAnimationFrame(playAnimationRef.current);
        playAnimationRef.current = requestAnimationFrame(repeat);    

    },[totalSeconds]);

    const VideoTimeUpdate = (data) => {

        SetProgressPos(100 - data.percent * 100);
        const timeString = helper.FormatTime(data.seconds); 
        SetTime(timeString);
    }

    const repeat = () => {
        
        if(audioRef.current)
        {
            const timeString = helper.FormatTime(audioRef.current.currentTime); 
            SetTime(timeString);

            SetProgressPos(100 - (audioRef.current.currentTime / totalSeconds) * 100);
        
            playAnimationRef.current = requestAnimationFrame(repeat);
        }
    };

    const TapToProgress = (e) => {

        const x = e.nativeEvent.offsetX;
        const width = e.nativeEvent.target.offsetWidth;

        const percentage = (x / width) * 100;

        if(audioRef.current)
        {
            var oldTime = audioRef.current.currentTime;
            if(oldTime == 0){
                audioRef.current.load();
                audioRef.current.pause();
            }
                
            audioRef.current.currentTime = totalSeconds / 100 * percentage;
        }

        if(videoRef.current)
        {
            videoRef.current.getDuration().then(function(duration) {
                videoRef.current.setCurrentTime(duration / 100 * percentage);
            }).catch(function(error) {
                
            });
            
        }

    }

    return(
        <div className="progress-bar-content">
            <div className="time">{time}</div>
            <div onClick={(e) => TapToProgress(e)} className="progress-bar">
                <div className="progress" style={{transform:"translateX(-" + progressPos + "%)"}}></div>
            </div>
            <div className="time">{totalDuration}</div>
        </div>
    )
}

export default ProgressBar;