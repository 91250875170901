import React, { useState, useContext, createContext, useEffect } from 'react';
import md5 from 'md5';

const authContext = createContext();
export const tokenName = md5("apttp_!@~`|=-65QhmZm733Dhfgk'§_-11!§");

export const AuthProvider = ({children}) => {

    const auth = useProviderAuth();

    return(
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(authContext);
}

const useProviderAuth = () => {

    const [token,SetToken]     = useState(null);
    const [loading,SetLoading] = useState(true);
    const [showAuth,SetShowAuth] = useState(false);

    useEffect(() => {
        var t = localStorage.getItem(tokenName);
        if(!t) t = null;
        SetToken(t);
        SetLoading(false);
    },[])

    const SaveToken = (token) => {
        localStorage.setItem(tokenName,token);
        SetToken(token);
    }

    const Logout = () => {
        SetShowAuth(true);
        localStorage.removeItem(tokenName);
        localStorage.removeItem("au-ap-s");
        SetToken(null);
        
    }

    const IsSignedIn = () => {
        return !!token;
    }

    return {
        loading,
        token,
        showAuth,
        SaveToken,
        IsSignedIn,
        Logout,
        SetShowAuth
    }

}