import React,{ Fragment, useState } from 'react';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import Notification from '../../../Library/notification';
import {CHANGE_PASSWORD} from '../Queries/changePassword';
import { useMutation, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

const ChangePassword = () => {

	const {t,i18n} = useTranslation();
	const client = useApolloClient();
		
	const [oldPassword, SetOldPassword] = useState("");
	const [newPassword, SetNewPassword] = useState("");
	const [checkNewPassword, SetcheckNewPassword] = useState("");

	const [changePassword,{error,loading}] = useMutation(CHANGE_PASSWORD,{
		update: async (cache, response) => {

			let notify = new Notification();

			if(response.data.changePassword !== ""){
				notify.setNotification(cache,response.data.changePassword,false,true);
			}else{	
				notify.setNotification(cache,t('SuccessfullyChanged'),true,false);
			}

		}
	});

	const ChangePassword = () => { 

        let notify = new Notification();  
        
        if(oldPassword !== ''){
			if(newPassword !== '' && checkNewPassword !== '' && newPassword === checkNewPassword){
				
				if(newPassword !== oldPassword){
					
					changePassword({
                        variables:{
                            password:newPassword,
                            oldPassword:oldPassword,
                            lang:i18n.language
                        }
                    });
					
				}else{
					notify.setNotification(null,t('OldAndNewPwdsMustNotBeSame'),false,true,client);
				}
				
			}else{
                notify.setNotification(null,t('NewPwdsNotSame'),false,true,client);
			}
		}else{
            notify.setNotification(null,t('NotFilledOldPwd'),false,true,client);
		}
    }
  	  
    return (
    	<div className="padd">		
    			
			<div className="card">
				
				<div className="card-header padd d-flex align-items-center">
					<span>{t('PasswordChange')}</span>
				</div>
				<div className="card-body">

					{error ?
						<Error text={error} />
					:
						<Fragment>
							{loading ?
								<Loading />
							:
								<div>
									<div className="row">
																
										<div className="col col-4 col-md-12">
											<label className="input-label">{t('OldPassword')}</label>
											<div className="form-group">
												<input className="form-control" type="password" name="oldPassword" onChange={(e) => SetOldPassword(e.target.value)} />
											</div>
										</div>
										<div className="col col-4 col-md-12">
											<label className="input-label">{t('NewPassword')}</label>
											<div className="form-group">
												<input className="form-control" type="password" name="newPassword" onChange={(e) => SetNewPassword(e.target.value)} />
											</div>
										</div>
										<div className="col col-4 col-md-12">
											<label className="input-label">{t('RepeatNewPwd')}</label>
											<div className="form-group">
												<input className="form-control" type="password" name="checkNewPassword" onChange={(e) => SetcheckNewPassword(e.target.value)} />
											</div>
										</div>
											
									</div>
									<div className="text-right">
									<button className="btn btn-primary" onClick={() => ChangePassword()}>{t('Change')}</button>
									</div>
								</div>
							}
						</Fragment>
					}
								
				</div>
			</div>			
    	</div>
    		
    );
  
}

export default ChangePassword;
